export default [
  {
    path: '/admin',
    name: 'admin',
    children: [
      {
        path: 'staffs',
        name: 'staffs',
        component: () => import(/* webpackChunkName: "sellers" */ '../view/apps/ecommerce/Sellers.vue'),
      },
      {
        path: 'branches',
        name: 'branches',
        component: () => import(/* webpackChunkName: "sellers" */ '../view/apps/ecommerce/Sellers.vue'),
      },
      {
        path: 'configs',
        name: 'configs',
        component: () => import(/* webpackChunkName: "sellers" */ '../view/apps/ecommerce/Sellers.vue'),
      },
      {
        path: 'paymethods',
        name: 'paymethods',
        component: () => import(/* webpackChunkName: "sellers" */ '../view/apps/ecommerce/Sellers.vue'),
      },
      {
        path: 'campaigns',
        name: 'campaigns',
        component: () => import(/* webpackChunkName: "sellers" */ '../view/tools/campaigns/index.vue'),
      },
    
    ],
  },

];
