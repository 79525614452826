export default [
  {
    path: '/store',
    name: 'store',
    children: [
      {
        path: 'promos',
        name: 'promos',
        component: () => import(/* webpackChunkName: "sellers" */ '../view/store/promos/index.vue'),
      },
      {
        path: 'coupons',
        name: 'coupons',
        component: () => import(/* webpackChunkName: "sellers" */ '../view/apps/ecommerce/Sellers.vue'),
      },
      {
        path: 'review',
        name: 'review',
        component: () => import(/* webpackChunkName: "sellers" */ '../view/apps/ecommerce/Sellers.vue'),
      },
      {
        path: 'paymethods',
        name: 'paymethods',
        component: () => import(/* webpackChunkName: "sellers" */ '../view/apps/ecommerce/Sellers.vue'),
      },
    
    ],
  },

];
